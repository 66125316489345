/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import styled from 'styled-components';

import { SEGMENT_LABELS } from '../../../../utils/enums';

import Line400kV from '../../../../assets/images/Line400kV.svg';
import LigneCCHT from '../../../../assets/images/LigneCCHT.svg';
import ProductionImg from '../../../../assets/images/production.svg';
import Flux from '../../../../assets/images/flux.svg';
import LegendSegment from './LegendSegment';
import Production from './Production';
import Consommation from './Consommation';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

import Eye from '../../../../assets/images/eye.svg';
import EyeClose from '../../../../assets/images/eye-close.svg';

import {
  selectToggleProduction,
  toggleProduction,
} from '../../../../store/mainReducer';

interface LegendProps {
  show: boolean;
  handleClose: () => void;
}

const StyledModal = styled(Modal)`
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  .modal-header {
    border-bottom: none;
  }
  .modal-content {
    border-radius: 2px;
    color: #ffffff;
    background: rgba(19, 98, 127, 1);
  }
  .modal-footer {
    border-top: none;

    .btn {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;

      &.btn-link {
        color: #6c6c6c;
        text-decoration: none;
      }
    }
  }

  .modal-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 10.879px;
  }
    .conso-icon {
      width: 24px;
      height: 24px;
    }
    .conso-value {
        padding-left: 11px;
    }
  .modal-body {
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    max-height: 25rem;
    overflow-y: auto;

    @media (max-width: 576px) {
      max-height: 25rem;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: rgba(19, 98, 127, 0.9);
    }

    &::-webkit-scrollbar {
      width: 12px;
      background-color: rgba(19, 98, 127, 0.9);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba(19, 98, 127, 0.9);
      border: 1px solid rgba(21, 37, 44, 1);
    }
  }

  .custom-dialog {
    @media (min-width: 576px) {
      top: 26%;
      margin-left: 22%;
    }
  }
`;

const LegendModal: React.FC<LegendProps> = ({ show, handleClose }) => {

  const handleCloseModal = (): void => {
    handleClose();
  };

  const dispatch = useAppDispatch();
  const activeProd = useAppSelector(selectToggleProduction);
  const handleClickProd = () => {
    dispatch(toggleProduction());
  };

  return (
    <StyledModal
      show={show}
      onHide={handleCloseModal}
      backdrop="static"
      keyboard={false}
      size={'sm'}
      dialogClassName="custom-dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title>{'Légende :'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12}>
            <div className="col-10 fs-6 mb-2">{'Réseau'}</div>
            <LegendSegment
              nameToggle={'line400kV'}
              title={SEGMENT_LABELS.FLUX}
              src={Flux}
              displayEye={true}
              tooltip={false}
            />
            <LegendSegment
              nameToggle={'line400kV'}
              title={SEGMENT_LABELS.LINE_400KV}
              src={Line400kV}
              displayEye={false}
              tooltip={false}
            />
            <LegendSegment
              nameToggle={'LigneCCHT'}
              title={SEGMENT_LABELS.LINE_CCHT}
              src={LigneCCHT}
              displayEye={false}
              tooltip={true}
            />
            <Production />
            <Consommation />
            <div className="col-12 fs-6 mt-2 mb-2">
              <div className="row">
                <div
                  onClick={handleClickProd}
                  style={{ cursor: 'pointer' }}
                  className="col-12"
                >
                  <div className="row pt-3 pb-2">
                    <div className="col-10 fs-6">{'Unités de production'}</div>
                    <div className="col-2 text-end">
                      <img src={activeProd ? Eye : EyeClose} alt="Eye" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pb-2 flex-nowrap">
                <div className="col-1">
                  <img className='conso-icon' src={ProductionImg} alt='' style={{width: 'auto', height: 'auto'}} />
                  </div>
                <div className="col-11 fs-6 conso-value">Volume de production sur site RTE</div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </StyledModal>
  );
};

export default LegendModal;
