import * as React from 'react';
import { DeckProps } from '@deck.gl/core/typed';
import { MapboxOverlay } from '@deck.gl/mapbox/typed';
import Map, { useControl, MapRef, LngLatBoundsLike } from 'react-map-gl';
import useDeviceWidth from '../../../utils/useDeviceDetect';

import { MAPBOX_ACCESS_TOKEN } from '../../../utils/constants';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import MapStyle from '../../../assets/map-styles/style.json';
import { fromJS } from 'immutable';
import usePathLayer from '../../layers/InitMap/usePathLayer';
import LegendModal from './legend';
import useCustomTripsLayer from '../../layers/InitMap/useCustomTripsLayer';
import useConsoLayer from '../../layers/InitMap/useConsoLayer';
import useProdLayer from '../../layers/InitMap/useProdLayer';
import useGouletLayer from '../../layers/goulet/useGouletLayer';
import useEuropeLayer from '../../layers/config/useEuropeLayer';
import useFranceBorderLayer from '../../layers/config/useFranceBorderLayer';
import useWaterLayer from '../../layers/config/useWaterLayer';
import useRegionsLayer from '../../layers/config/useRegionsLayer';

import {
  selectModalSimulator,
  setCurrentZoom,
  toggleModalSimulator,
  selectCurrentZoom,
  selectCurrentYear,
  setSegments,
  setCurrentZoomActive,
  selectTooltip,
} from '../../../store/mainReducer';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

import { getSegmentsByYear } from '../../../services';
import usePointIconsLayer from '../../layers/poinIn/usePointIconsLayer';
import PointInterestMap from '../../pointIn';
import Tooltip from '../../../shared/Tooltip';

const StyledDiv = styled.div`
  .btn-cf1 {
    position: relative;
    background: #e9f7fc;
    color: #004d66;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
    text-decoration: none;
  }
  .position-absolute {
    z-index: 11;
  }
  .icon-Button {
   font-size: 1.8rem;
  }
`;

function DeckGLOverlay(props: DeckProps) {
  const deck = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  deck.setProps(props);
  return null;
}

export default function MapAreaComponent() {
  const isMobile = useDeviceWidth();

  const selectedModel = useAppSelector(selectModalSimulator);
  const currentZoom = useAppSelector(selectCurrentZoom);
  const currentYear = useAppSelector(selectCurrentYear);
  const currentTooltip = useAppSelector(selectTooltip);

  const dispatch = useAppDispatch();

  const franceBounds: LngLatBoundsLike = [
    [-5.142222, 41.364],
    [9.561556, 51.089062],
  ];

  const mapRef = React.useRef<MapRef>() as React.Ref<MapRef>;

  const pathLayer = usePathLayer();
  const tripsLayer = useCustomTripsLayer();
  const franceBorder = useFranceBorderLayer();
  const europeLayer = useEuropeLayer();
  const waterLayer = useWaterLayer();
  const consoLayer = useConsoLayer();
  const prodLayer = useProdLayer();
  const regionsLayer = useRegionsLayer();
  const gouletsLayer = useGouletLayer();
  const pointIconsLayer = usePointIconsLayer();

  const handleInitZoom = () => {
    dispatch(setCurrentZoom(null));

    dispatch(setCurrentZoomActive(true));

    // change network files when clicking a point of interest
    const data_segment = getSegmentsByYear(currentYear);

    dispatch(setSegments(data_segment));
  };

  const handleCloseLegend = (): void => {
    dispatch(toggleModalSimulator(false));
  };
  const handleShowLegend = (): void => {
    dispatch(toggleModalSimulator(true));
  };

  return (
    <>
      <Map
        initialViewState={{
          longitude: 2.5,
          latitude: 46.5,
          bounds: franceBounds,
          fitBoundsOptions: { padding: isMobile ? 0 : 30 },
        }}
        mapStyle={fromJS(MapStyle) as any}
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        ref={mapRef}
        dragRotate={false}
        dragPan={false}
        touchZoomRotate={false}
        doubleClickZoom={false}
        scrollZoom={false}
        cursor="pointer"
        style={{ position: 'relative' }}
      >
        <DeckGLOverlay
          layers={[
            ...regionsLayer,
            ...consoLayer,
            ...waterLayer,
            ...europeLayer,
            ...franceBorder,
            ...prodLayer,
            ...pathLayer,
            ...tripsLayer,

            ...(currentZoom === null ? gouletsLayer : []),
            ...pointIconsLayer,
          ]}
        />
        <StyledDiv
          className="position-absolute"
          style={{
            bottom: '10px',
            left: isMobile ? '1px' : '10px',
            zIndex: isMobile ? 11 : 1500,
          }}
        >
          {!isMobile ? (
            <Button
              className="btn-cf"
              variant="link"
              onClick={handleShowLegend}
            >
              <span className="icon icon-Button">
                <span className="path1"></span>
                <span className="path2"></span>
              </span>
            </Button>
          ) : (
            <Button
              className="btn-cf1"
              variant="link"
              onClick={handleShowLegend}
            >
              {'Légende'}
            </Button>
          )}
        </StyledDiv>

        {/* <Button
          className="btn-cf position-absolute p-0"
          style={{
            top: '10px',
            left: '10px',
            width: 'auto',
            zIndex: isMobile ? 11 : 1500,
          }}
          variant="secondary"
          onClick={handleInitZoom}
        >
          <img src={InitZoom} alt="" width="32px" height="32px" />
        </Button> */}
      </Map>
      <LegendModal show={selectedModel} handleClose={handleCloseLegend} />
      {currentZoom !== null && (
        <PointInterestMap
          currentZoom={currentZoom}
          handleClose={handleInitZoom}
        />
      )}
      {currentTooltip !== null && (
        <Tooltip
          layer={currentTooltip.layer}
          x={currentTooltip.x}
          y={currentTooltip.y}
          info={currentTooltip}
        />
      )}
    </>
  );
}
