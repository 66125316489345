import React from 'react';
import { useAppSelector } from '../../../store/hooks';
import {
  selectIndustryPointIn,
  selectNumberActivePointIn,
} from '../../../store/mainReducer';
import { IndustryPointFeature } from '../../../models/ZoomFeature';
import { IconLayer } from '@deck.gl/layers/typed';
import Industry from '../../../assets/images/Industry.svg';

const useIndustryPointLayer = (): Array<IconLayer<IndustryPointFeature>> => {
  const industryPointIn = useAppSelector(selectIndustryPointIn);
  const activePointIn = useAppSelector(selectNumberActivePointIn);

  const layerLignePoint = new IconLayer<IndustryPointFeature>({
    id: 'industry-point-layer',
    data: industryPointIn,
    getIcon: (d: any) => ({
      url: Industry,
      width: 41,
      height: 64,
    }),
    getPosition: (d: any) => {
      return d.position;
    },
    getSize: (d) => 64,
    visible: true,
    pickable: true,
    opacity: activePointIn === 3 || activePointIn === 0 ? 1 : 0.1,
  });

  return [layerLignePoint];
};

export default useIndustryPointLayer;
