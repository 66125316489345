import { IconLayer } from '@deck.gl/layers/typed';

import {
  selectCurrentGouletZone,
  selectCurrentYear,
  selectGouletSymbols,
  selectStateClimate,
} from '../../../store/mainReducer';
import { useAppSelector } from '../../../store/hooks';
import { GouletSymbolFeature } from '../../../models/GouletSymbolFeature';
import { splitYear } from '../../../utils';

import B_2030_EJCS_flux from '../../../assets/images/symbols/B_2030_EJCS_flux.svg';
import B_2030_HNVC_flux from '../../../assets/images/symbols/B_2030_HNVC_flux.svg';
import B_2035_EJCS_flux from '../../../assets/images/symbols/B_2035_EJCS_flux.svg';
import B_2035_HNVC_flux from '../../../assets/images/symbols/B_2035_HNVC_flux.svg';
import B_2040_EJCS_flux from '../../../assets/images/symbols/B_2040_EJCS_flux.svg';
import B_2040_HNVC_flux from '../../../assets/images/symbols/B_2040_HNVC_flux.svg';

import C_2030_EJCS_flux from '../../../assets/images/symbols/C_2030_EJCS_flux.svg';
import C_2030_HNVC_flux from '../../../assets/images/symbols/C_2030_HNVC_flux.svg';
import C_2035_EJCS_flux from '../../../assets/images/symbols/C_2035_EJCS_flux.svg';
import C_2035_HNVC_flux from '../../../assets/images/symbols/C_2035_HNVC_flux.svg';
import C_2040_EJCS_flux from '../../../assets/images/symbols/C_2040_EJCS_flux.svg';
import C_2040_HNVC_flux from '../../../assets/images/symbols/C_2040_HNVC_flux.svg';

import E_2030_EJCS_flux from '../../../assets/images/symbols/E_2030_EJCS_flux.svg';
import E_2030_HNVC_flux from '../../../assets/images/symbols/E_2030_HNVC_flux.svg';
import E_2035_EJCS_flux from '../../../assets/images/symbols/E_2035_EJCS_flux.svg';
import E_2035_HNVC_flux from '../../../assets/images/symbols/E_2035_HNVC_flux.svg';
import E_2040_EJCS_flux from '../../../assets/images/symbols/E_2040_EJCS_flux.svg';
import E_2040_HNVC_flux from '../../../assets/images/symbols/E_2040_HNVC_flux.svg';

import I_2030_EJCS_flux from '../../../assets/images/symbols/I_2030_EJCS_flux.svg';
import I_2030_HNVC_flux from '../../../assets/images/symbols/I_2030_HNVC_flux.svg';
import I_2035_EJCS_flux from '../../../assets/images/symbols/I_2035_HNVC_flux.svg';
import I_2035_HNVC_flux from '../../../assets/images/symbols/I_2035_HNVC_flux.svg';
import I_2040_EJCS_flux from '../../../assets/images/symbols/I_2040_EJCS_flux.svg';
import I_2040_HNVC_flux from '../../../assets/images/symbols/I_2040_HNVC_flux.svg';

const useFluxGouletLayer = () => {
  const currentYear = useAppSelector(selectCurrentYear);
  const currentGouletZone = useAppSelector(selectCurrentGouletZone);
  const currentClimat = useAppSelector(selectStateClimate);
  const gouletSymbol = useAppSelector(selectGouletSymbols);

  const getSymbolData = () => {
    return gouletSymbol.filter((item: GouletSymbolFeature) => {
      const { categorie } = item.properties;
      const mix = splitYear(currentYear).toString();
      const prefix = `${currentGouletZone}_${mix}_${currentClimat}_flux`;
      return categorie.startsWith(prefix);
    });
  };

  const getSymbolIcon = (categorie: string): any => {
    switch (categorie) {
      case 'B_2030_EJCS_flux':
        return {
          url: B_2030_EJCS_flux,
          width: 312,
          height: 446,
        };
      case 'B_2030_HNVC_flux':
        return {
          url: B_2030_HNVC_flux,
          width: 300,
          height: 231,
        };
      case 'B_2035_EJCS_flux':
        return {
          url: B_2035_EJCS_flux,
          width: 312,
          height: 446,
        };
      case 'B_2035_HNVC_flux':
        return {
          url: B_2035_HNVC_flux,
          width: 388,
          height: 245,
        };

      case 'B_2040_EJCS_flux':
        return {
          url: B_2040_EJCS_flux,
          width: 312,
          height: 446,
        };
      case 'B_2040_HNVC_flux':
        return {
          url: B_2040_HNVC_flux,
          width: 388,
          height: 254,
        };

      case 'C_2030_EJCS_flux':
        return {
          url: C_2030_EJCS_flux,
          width: 180,
          height: 380,
        };
      case 'C_2030_HNVC_flux':
        return {
          url: C_2030_HNVC_flux,
          width: 240,
          height: 410,
        };
      case 'C_2035_EJCS_flux':
        return {
          url: C_2035_EJCS_flux,
          width: 180,
          height: 380,
        };
      case 'C_2035_HNVC_flux':
        return {
          url: C_2035_HNVC_flux,
          width: 240,
          height: 410,
        };

      case 'C_2040_EJCS_flux':
        return {
          url: C_2040_EJCS_flux,
          width: 180,
          height: 380,
        };
      case 'C_2040_HNVC_flux':
        return {
          url: C_2040_HNVC_flux,
          width: 240,
          height: 410,
        };

      case 'E_2030_EJCS_flux':
        return {
          url: E_2030_EJCS_flux,
          width: 253,
          height: 234,
        };
      case 'E_2030_HNVC_flux':
        return {
          url: E_2030_HNVC_flux,
          width: 292,
          height: 517,
        };
      case 'E_2035_EJCS_flux':
        return {
          url: E_2035_EJCS_flux,
          width: 253,
          height: 234,
        };
      case 'E_2035_HNVC_flux':
        return {
          url: E_2035_HNVC_flux,
          width: 398,
          height: 517,
        };

      case 'E_2040_EJCS_flux':
        return {
          url: E_2040_EJCS_flux,
          width: 253,
          height: 234,
        };
      case 'E_2040_HNVC_flux':
        return {
          url: E_2040_HNVC_flux,
          width: 398,
          height: 517,
        };

      case 'I_2030_EJCS_flux':
        return {
          url: I_2030_EJCS_flux,
          width: 328,
          height: 241,
        };
      case 'I_2030_HNVC_flux':
        return {
          url: I_2030_HNVC_flux,
          width: 328,
          height: 241,
        };
      case 'I_2035_EJCS_flux':
        return {
          url: I_2035_EJCS_flux,
          width: 328,
          height: 241,
        };
      case 'I_2035_HNVC_flux':
        return {
          url: I_2035_HNVC_flux,
          width: 539,
          height: 241,
        };

      case 'I_2040_EJCS_flux':
        return {
          url: I_2040_EJCS_flux,
          width: 328,
          height: 241,
        };
      case 'I_2040_HNVC_flux':
        return {
          url: I_2040_HNVC_flux,
          width: 539,
          height: 241,
        };
      default:
        return;
    }
  };

  const layerFlux = new IconLayer({
    id: 'flux-layer',
    data: getSymbolData(),
    getIcon: (d: any) => getSymbolIcon(d.properties.categorie),
    getPosition: (d: any) => {
      return d.position;
    },
    getSize: (d: any) => getSymbolIcon(d.properties.categorie).height,
    getPixelOffset: (d: any) => [0, 0],
    getColor: () => [255, 255, 255, 200],
    pickable: true,
  });

  return [layerFlux];
};

export default useFluxGouletLayer;
