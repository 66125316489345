import React from 'react';
import styled from 'styled-components';
import LineIcons from '../assets/images/points/lineIcons.svg';
import { getGroupGoulets } from '../utils';
import { useAppSelector } from '../store/hooks';
import { selectGoulets } from '../store/mainReducer';

interface ComponentProps {
  info: any;
  x: number;
  y: number;
  layer: any;
}

const StyledDiv = styled.div`
  &.tooltip-point {
    position: absolute;
    color: #ffffff;
    pointer-events: none;
    z-index: 1500;
    width: 50px;
    height: 40px;
    text-align: justify;
  }

  .tooltip-content {
    background-color: transparent;
    text-align: left;
    font-family: Nunito Sans;
    font-size: 13px;
    font-weight: 400;
    line-height: 17.73px;
    text-align: right;
  }

  .tooltip-arrow {
    position: absolute;
    width: 29px;
    height: 16px;
    margin-left: 0px;
    top: 20px;
    left: 50px;
  }
`;

const Tooltip: React.FC<ComponentProps> = ({ layer, x, y, info }) => {
  const goulets = useAppSelector(selectGoulets);

  // console.log('info', layer.id, info.name);

  const getInfoText = () => {
    let text = '';
    if (info.name && layer?.id === 'goulets') {
      text = `Groupe ${
        info.name.zone
      }'\n'Goulet ${getGroupGoulets(goulets, info.name.id)}`;
    }

    if (info.name && x && y && layer?.id === 'points-icon-layer') {
      text = `Zone de ${info.name.Nom_comp}`;
    }

    if (x && y && (layer?.id === 'contrainte-icon-layer' || layer?.id === 'goulets')) {
      text = `Zone de contrainte`;
    }
    return text;
  };

  return (
    <StyledDiv
      className="tooltip-point"
      style={{
        top: `${Math.round(y) - 40}px`,
        left: `${Math.round(x) - 80}px`,
      }}
    >
      <div className="tooltip-content">{getInfoText()}</div>
      <img alt="" src={LineIcons} className="tooltip-arrow" />
    </StyledDiv>
  );
};

export default Tooltip;
