import SliderByYearDay from '../../shared/SliderByYearDay';
import { YEARS_B_REF } from '../../utils/constants';

import ItemLegendPoint from './ItemLegendPoint';

const LeftAreaComponent = () => {
  return (
    <div
      className="row p-2 px-3 pr-3"
      style={{ position: 'relative', zIndex: 1500 }}
    >
      <div className="col-12 mt-2">
        <h5 className="mb-0">{'Mix énergétique :'}</h5>
      </div>
      <div className="btn-year">
        <SliderByYearDay years={YEARS_B_REF} />
      </div>
      <ItemLegendPoint />
    </div>
  );
};

export default LeftAreaComponent;
