import { IconLayer } from '@deck.gl/layers/typed';

import {
  selectCurrentGouletZone,
  selectCurrentYear,
  selectGouletSymbols,
  selectStateClimate,
} from '../../../store/mainReducer';
import { useAppSelector } from '../../../store/hooks';
import { GouletSymbolFeature } from '../../../models/GouletSymbolFeature';
import { splitYear } from '../../../utils';

import B_2030_EJCS_echange_allemagne from '../../../assets/images/symbols/B_2030_EJCS_echange_allemagne.svg';
import B_2030_HNVC_echange_allemagne from '../../../assets/images/symbols/B_2030_HNVC_echange_allemagne.svg';
import B_2035_EJCS_echange_allemagne from '../../../assets/images/symbols/B_2035_EJCS_echange_allemagne.svg';
import B_2035_HNVC_echange_allemagne from '../../../assets/images/symbols/B_2035_HNVC_echange_allemagne.svg';
import B_2040_EJCS_echange_allemagne from '../../../assets/images/symbols/B_2040_EJCS_echange_allemagne.svg';
import B_2040_HNVC_echange_allemagne from '../../../assets/images/symbols/B_2040_HNVC_echange_allemagne.svg';

import I_2030_EJCS_echange_allemagne from '../../../assets/images/symbols/I_2030_EJCS_echange_allemagne.svg';
import I_2030_HNVC_echange_allemagne from '../../../assets/images/symbols/I_2030_HNVC_echange_allemagne.svg';
import I_2035_EJCS_echange_allemagne from '../../../assets/images/symbols/I_2035_EJCS_echange_allemagne.svg';
import I_2035_HNVC_echange_allemagne from '../../../assets/images/symbols/I_2035_HNVC_echange_allemagne.svg';
import I_2040_EJCS_echange_allemagne from '../../../assets/images/symbols/I_2040_EJCS_echange_allemagne.svg';
import I_2040_HNVC_echange_allemagne from '../../../assets/images/symbols/I_2040_HNVC_echange_allemagne.svg';

const useExchangeAllemagneGouletLayer = () => {
  const currentYear = useAppSelector(selectCurrentYear);
  const currentGouletZone = useAppSelector(selectCurrentGouletZone);
  const currentClimat = useAppSelector(selectStateClimate);
  const gouletSymbol = useAppSelector(selectGouletSymbols);

  const getSymbolData = () => {
    return gouletSymbol.filter((item: GouletSymbolFeature) => {
      const { categorie } = item.properties;
      const mix = splitYear(currentYear).toString();
      const prefix = `${currentGouletZone}_${mix}_${currentClimat}_echange_allemagne`;
      return categorie.startsWith(prefix);
    });
  };

  const getSymbolIcon = (categorie: string): any => {
    switch (categorie) {
      case 'B_2030_EJCS_echange_allemagne':
        return {
          url: B_2030_EJCS_echange_allemagne,
          width: 36,
          height: 30,
        };
      case 'B_2030_HNVC_echange_allemagne':
        return {
          url: B_2030_HNVC_echange_allemagne,
          width: 36,
          height: 30,
        };
      case 'B_2035_EJCS_echange_allemagne':
        return {
          url: B_2035_EJCS_echange_allemagne,
          width: 36,
          height: 30,
        };
      case 'B_2035_HNVC_echange_allemagne':
        return {
          url: B_2035_HNVC_echange_allemagne,
          width: 36,
          height: 30,
        };

      case 'B_2040_EJCS_echange_allemagne':
        return {
          url: B_2040_EJCS_echange_allemagne,
          width: 36,
          height: 30,
        };
      case 'B_2040_HNVC_echange_allemagne':
        return {
          url: B_2040_HNVC_echange_allemagne,
          width: 36,
          height: 30,
        };

      case 'I_2030_EJCS_echange_allemagne':
        return {
          url: I_2030_EJCS_echange_allemagne,
          width: 36,
          height: 30,
        };
      case 'I_2030_HNVC_echange_allemagne':
        return {
          url: I_2030_HNVC_echange_allemagne,
          width: 36,
          height: 30,
        };
      case 'I_2035_EJCS_echange_allemagne':
        return {
          url: I_2035_EJCS_echange_allemagne,
          width: 36,
          height: 30,
        };
      case 'I_2035_HNVC_echange_allemagne':
        return {
          url: I_2035_HNVC_echange_allemagne,
          width: 36,
          height: 30,
        };

      case 'I_2040_EJCS_echange_allemagne':
        return {
          url: I_2040_EJCS_echange_allemagne,
          width: 36,
          height: 30,
        };
      case 'I_2040_HNVC_echange_allemagne':
        return {
          url: I_2040_HNVC_echange_allemagne,
          width: 36,
          height: 30,
        };
      default:
        return;
    }
  };

  const layer = new IconLayer({
    id: 'echange-allemagne-layer',
    data: getSymbolData(),
    getIcon: (d: any) => getSymbolIcon(d.properties.categorie),
    getPosition: (d: any) => {
      return d.position;
    },
    getSize: (d: any) => getSymbolIcon(d.properties.categorie).height,
    getColor: () => [255, 255, 255, 255],
    pickable: true,
  });

  return [layer];
};

export default useExchangeAllemagneGouletLayer;
