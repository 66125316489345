import { useAppSelector } from '../../../store/hooks';
import {
  selectCurrentGouletZone,
  selectSites,
  selectToggleProduction,
} from '../../../store/mainReducer';
import { ScatterplotLayer } from '@deck.gl/layers/typed';
import { SiteFeature } from '../../../models/SiteFeature';

const useProdLayer = (): Array<ScatterplotLayer<SiteFeature>> => {
  const active = useAppSelector(selectToggleProduction);
  const sites = useAppSelector(selectSites);
  const currentGouletZone = useAppSelector(selectCurrentGouletZone);

  const scatterplot225 = new ScatterplotLayer<SiteFeature>({
    id: 'scatterplot225-layer-1',
    data: sites.filter(
      (item) => item.properties.TOTAL_PROD && item.properties.TOTAL_PROD > 0,
    ),
    getPosition: (d) => d.position as any,
    getRadius: (d) => {
      return d.prod ? d.prod * 600 : 0;
    },
    stroked: true,
    visible: active && currentGouletZone === '',
    getFillColor: [37, 96, 125, 100],
    getLineColor: [37, 96, 125],
    getLineWidth: 1000,
  });

  return [scatterplot225];
};

export default useProdLayer;
