import * as React from 'react';

import styled from 'styled-components';
import { ZoomPointFeature } from '../../models/ZoomFeature';
import LeftPointComponent from './LeftPointComponent';
import RightPointComponent from './RightPointComponent';
import { useAppDispatch } from '../../store/hooks';
import ContentPointInComponent from './ContentPointInComponent';
import { setElementHeightPointIn } from '../../store/mainReducer';

interface ModalProps {
  currentZoom: ZoomPointFeature;
  handleClose: () => void;
}

const StyledDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1500;
`;

const PointInterestMap: React.FC<ModalProps> = ({
  currentZoom,
  handleClose,
}) => {
  const dispatch = useAppDispatch();

  const elementRef = React.useRef<any>(null); // Create a ref to the element

  React.useEffect(() => {
    if (elementRef.current) {
      // Access the height of the element
      const elementHeight = elementRef.current.clientHeight;
      dispatch(setElementHeightPointIn(elementHeight)); // Set the height in state
    }
  }, [dispatch]); // Empty dependency array to run on mount

  const handleCloseModal = (): void => {
    handleClose();
  };

  return (
    <StyledDiv>
      <div className="container-fluid">
        <div className="row vh-100-md p-10" id="content">
          <div
            className="col-12 order-1 order-md-0 col-md-2 d-none d-md-block sidebar"
            id="left-area"
          >
            <LeftPointComponent />
          </div>
          <div
            className="col-12 order-0 order-md-1 col-md-8 vh-md-100 vh-sm-100 pe-2 ps-2 shadow-sm"
            id="center-area"
            ref={elementRef}
          >
            <ContentPointInComponent handleClose={handleCloseModal} />
          </div>
          <div
            className="col-12 order-2 order-md-2 col-md-2 d-none d-md-block sidebar"
            id="right-area"
          >
            <RightPointComponent />
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};

export default PointInterestMap;
