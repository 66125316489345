import { useAppSelector } from '../../../store/hooks';
import {
  selectCurrentGouletZone,
  selectCurrentYear,
  selectGouletSymbols,
  selectStateClimate,
} from '../../../store/mainReducer';
import { IconLayer, ScatterplotLayer } from '@deck.gl/layers/typed';
import PointProd from '../../../assets/images/goulet/pointProd.svg';
import { GouletSymbolFeature } from '../../../models/GouletSymbolFeature';
import { CURRENT_YEAR_VALUES } from '../../../utils/enums';

const useProdGouletLayer = () => {
  const currentYear = useAppSelector(selectCurrentYear);
  const currentGouletZone = useAppSelector(selectCurrentGouletZone);
  const currentClimat = useAppSelector(selectStateClimate);
  const gouletSymbolsProd = useAppSelector(selectGouletSymbols).filter(item => {
    const { categorie } = item.properties;
    const mix = CURRENT_YEAR_VALUES[currentYear as keyof typeof CURRENT_YEAR_VALUES];
    const prefix = `${currentGouletZone}_${mix}_${currentClimat}_nuc_`;
    return categorie.startsWith(prefix);
  });
  const scatterplot225 = new ScatterplotLayer<GouletSymbolFeature>({
    id: 'prod-layer-1',
    data: gouletSymbolsProd,
    getPosition: (d: any) => d.position,
    getRadius: (d) => {
      return Number(d.properties.variable) * 500;
    },
    stroked: true,
    getFillColor: [244, 221, 11, 50],
    getLineColor: [244, 221, 11],
    getLineWidth: 1000,
  });

  const layerPoint = new IconLayer({
    id: 'point-layer-1',
    data: gouletSymbolsProd,
    getIcon: () => ({
      url: PointProd,
      width: 4,
      height: 4,
    }),
    getPosition: (d: any) => {
      return d.position;
    },
    getSize: () => 4,
    pickable: true,
  });

  return [scatterplot225, layerPoint];
};

export default useProdGouletLayer;
