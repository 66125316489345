import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { getLegendColor, getLegendName } from '../utils';

interface ComponentProps {
  data: any;
}

const StyledRow = styled(Row)`
  &.bar-chart-row {
    flex-wrap: wrap;
    column-gap: 0;
    height: 100%;
    align-items: center;
    row-gap: 20px;
    align-content: flex-start;
    @media (min-width: 576px) and (max-width: 992px) {
      align-content: flex-start;
    }
    .bar {
      height: 9.4rem;
    }
  }

  .bar-chart {
    background-color: var(--color-primary);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    height: inherit;
    width: 1.8em;
    overflow: hidden;
  }

  .bar-data {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bar-text {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 0.2rem;
  }
  .icon {
    display: inline-block;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
    margin-right: 0.4rem;
    overflow: hidden;
    padding: 0.4rem;
  }

  .text {
    justify-content: space-between;
    width: 100%;
    font-size: 0.9rem;
    display: flex;
  }
`;

const calculatePercentage = (total: number, value: number) => {
  const result = (value / total) * 100;
  return result.toFixed(2); // rounding to 2 decimal places
};

const BarByChart: React.FC<ComponentProps> = ({ data }) => {
  return (
    <div className="col-12">
      <StyledRow className="bar-chart-row">
        <Col xs="2" md={'2'} className="">
          <div className="bar">
            <div className="bar-chart">
              {data &&
                data.productions.map((d: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className="bar-data"
                      style={{
                        background: `${getLegendColor(d.sector)}`,
                        height: `${calculatePercentage(
                          data.total_prod,
                          d.value,
                        )}%`,
                      }}
                    ></div>
                  );
                })}
            </div>
          </div>
        </Col>
        <Col xs="10" md="10">
          <Row>
            {data.productions.map((d: any, index: number) => {
              return (
                <Col md={'12'} key={index} className="bar-text">
                  <div
                    className="icon"
                    style={{ background: getLegendColor(d.sector) }}
                  ></div>
                  <div className="text">
                    <span>{getLegendName(d.sector)}</span>
                    <span>{d.value + ' GW'}</span>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
      </StyledRow>
    </div>
  );
};

export default BarByChart;
