import { LignePointFeature } from '../../../models/ZoomFeature';
import { PathLayer } from '@deck.gl/layers/typed';
import { useAppSelector } from '../../../store/hooks';
import {
  selectLignesPointIn,
  selectNumberActivePointIn,
} from '../../../store/mainReducer';

const useLignesPointLayer = (): Array<PathLayer<LignePointFeature>> => {
  const lignesPointIn = useAppSelector(selectLignesPointIn);
  const activePointIn = useAppSelector(selectNumberActivePointIn);

  const paths_1 = new PathLayer({
    id: 'path',
    data: lignesPointIn,
    getPath: (d) => d.position,
    getWidth: (d) => d.size,
    widthUnits: 'pixels',
    getColor: (d: any) => d.color,
    opacity: activePointIn === 1 || activePointIn === 0 ? 1 : 0.1,
  });

  return [paths_1];
};

export default useLignesPointLayer;
