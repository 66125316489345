import React, { type ReactNode } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import styled from 'styled-components';

const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    background-color: #fff;
    color: #000;
    border: 1px solid #fff;
  }

  .tooltip-arrow {
    border-top-color: #fff;
  }

  .tooltip-arrow::before {
    border-top-color: #fff;
    border-bottom-color: #fff;
  }
`;

interface TooltipTriggerProps {
  children?: ReactNode;
  description: string;
}
const TooltipTrigger: React.FC<TooltipTriggerProps> = ({
  children,
  description,
}) => {
  const renderTooltip = (props: any) => (
    <StyledTooltip id="button-tooltip" {...props}>
      {description}
    </StyledTooltip>
  );

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 250 }}
      overlay={renderTooltip}
    >
      <div>{children}</div>
    </OverlayTrigger>
  );
};

export default TooltipTrigger;
