import { numberFormatFr } from '../utils';

export const optionsChart = {
  responsive: true,
  maintainAspectRatio: false,
  showScale: true,
  resizeDelay: 0,
  scaleShowValues: true,
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 20,
      bottom: 0,
    },
  },
  scales: {
    x: {
      beginAtZero: false,
      stackWeight: 1,
      grid: {
        drawBorder: true,
        drawTicks: true,
        drawOnChartArea: true,
      },
      gridLines: {
        display: true,
      },
      ticks: {
        maxTicksLimit: 24,
        padding: 0,
        beginAtZero: true,
        color: 'white',
        autoSkip: true,
        fontSize: 8,
        // maxRotation: 90,
        // minRotation: 90,
        stepSize: 10,
      },
    },
    y: {
      beginAtZero: true,
      stackWeight: 1,
      grid: {
        drawBorder: false,
        drawTicks: true,
        drawOnChartArea: false,
      },
      gridLines: {
        display: false,
      },
      ticks: {
        padding: 0,
        beginAtZero: false,
        color: 'white',
        autoSkip: false,
        fontSize: 6,
        maxRotation: 0,
        minRotation: 0,
        // Custom callback for Y-axis labels
        callback: function (value: number | string) {
          return value;
        },
      },
    },
  },
  plugins: {
    legend: {
      display: false,
      position: 'top' as const,
    },
    title: {
      display: false,
    },
    tooltip: {
      enabled: false,
      displayColors: false,
      intersect: false,
      // mode: 'index',
      callbacks: {
        title: (tooltipItems: any) => {
          return `${tooltipItems[0].label}`; // Customize title
        },
        label: (tooltipItem: any) => {
          return `${numberFormatFr(tooltipItem.raw)} GW`; // Customize label
        },
      },
    },
    hover: {
      intersect: false,
      mode: 'index',
    },
  },
};

// Plugin to draw the vertical line
export const verticalFixedLinePlugin = (
  selectHour: number,
  currentHour: string,
): any => {
  return {
    id: 'verticalFixedLinePlugin',
    afterDraw: (chart: any) => {
      const ctx = chart.ctx;
      const xAxis = chart.scales.x;
      const yAxis = chart.scales.y;

      // Get the pixel location of the vertical line
      const xValue = xAxis.getPixelForValue(selectHour);

      // Draw the vertical line
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(xValue, yAxis.top);
      ctx.lineTo(xValue, yAxis.bottom);
      ctx.lineWidth = 2;
      ctx.strokeStyle = 'rgba(75,192,192,1)';
      ctx.stroke();

      // Set text style
      ctx.fillStyle = '#FFFFFF';
      ctx.textAlign = 'center';
      ctx.font = '14px Arial';
      ctx.textBaseline = 'middle';
      ctx.fillText(`${currentHour}`, xValue, yAxis.top - 12);

      chart.update();
      ctx.restore();
    },
  };
};

// Custom plugin to draw vertical line and show tooltip
export const verticalLinePlugin = {
  id: 'verticalLinePlugin',
  afterDraw: (chart: any) => {
    if (chart.tooltip._active && chart.tooltip._active.length) {
      const ctx = chart.ctx;
      const activePoint = chart.tooltip._active[0];
      const x = activePoint.element.x;
      const topY = chart.scales.y.top;
      const bottomY = chart.scales.y.bottom;

      // Draw vertical line
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 2;
      ctx.strokeStyle = 'rgba(75,192,192,1)';

      ctx.textAlign = 'center';
      // ctx.fillText(currentHour, x, chart.scales.startPoint + 12);

      ctx.stroke();
      ctx.restore();
    }
  },
};

export const getDataProd = (dataConso: any) => {
  return {
    labels: dataConso.map((elt: any) => elt.hour),
    datasets: [
      {
        label: '',
        data: dataConso.map((elt: any) =>
          parseFloat(elt.value.replace(',', '.')),
        ),
        fill: true,
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
        tension: 0.5,
        pointStyle: 'line',
        aspectRatio: 2,
      },
    ],
  };
};
