import { Accordion } from 'react-bootstrap';
import styled from 'styled-components';

import { useAppSelector } from '../../../store/hooks';
import {
  selectCurrentYear,
  selectSidebarProductions,
  selectStateClimate,
} from '../../../store/mainReducer';
import { numberFormatFr } from '../../../utils';
import BarByChart from '../../../shared/BarByChart';
import SliderByYear from '../../../shared/SliderByYear';
import AreaChart from '../../../shared/AreaChart';

import Import from '../../../assets/images/import.svg';
import Export from '../../../assets/images/export.svg';
import ArrowDown from '../../../assets/images/arrow_cool_down.svg';
import { YEARS_B_REF } from '../../../utils/constants';

const StyledAccordion = styled(Accordion)`
  .accordion-item {
    color: rgba(255, 255, 255, 1);
    background-color: transparent;
    border: none;
    margin-bottom: 1rem;
    border-radius: 0.6rem;
  }

  .accordion-button {
    color: rgba(255, 255, 255, 1);
    background-color: transparent;
    box-shadow: none;
    border: none;
  }

  .accordion-button:not(.collapsed) {
    color: rgba(255, 255, 255, 1);
    background-color: rgba(10, 90, 114, 1);
    box-shadow: none;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .accordion-button.collapsed {
    border: 0.5px solid rgba(42, 87, 103, 1);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    span {
      font-family: Nunito Sans;
      font-size: 13px;
      font-weight: 400;
      line-height: 1;
      text-align: left;
      color: rgba(183, 183, 183, 1);
      position: relative;
      top: -4px;
    }
  }
  .accordion-button::after {
    display: none;
  }
  .accordion-button {
    padding: 10px 6px;
    font-size: 1rem;
    color: rgba(255, 255, 255, 1);
    background-color: transparent;
  }
  .accordion-collapse {
    background-color: rgba(10, 90, 114, 1);
  }
  .accordion-body {
    padding-left: 0.8rem;
    padding-top: 0;
  }
`;

const LeftAreaComponent = () => {
  const currentYear = useAppSelector(selectCurrentYear);
  const selectedClimate = useAppSelector(selectStateClimate);
  const selectedProductions = useAppSelector(selectSidebarProductions);

  const currentProd = selectedProductions.filter(
    (elt: any) => elt.day === 'EJCS',
  )[0];

  return (
    <div className="row px-4 ">
      <div className="col-12 mt-2 mb-3">
        <h5>{'Mix énergétique :'}</h5>
        <SliderByYear years={YEARS_B_REF} />
      </div>

      <div className="content p-3">
        {currentProd && (
          <div className="row">
            <div className="col-12">
              <h5>{`Production : ${numberFormatFr(
                currentProd.total_prod,
              )} GW`}</h5>
            </div>
            <div className="col-12">
              <BarByChart data={currentProd} />
            </div>
            <div className="col-12 mt-3">
              <h5>{`Consommation : ${numberFormatFr(
                currentProd.total_conso,
              )} GW`}</h5>
            </div>
            <div className="col-12">
              <AreaChart
                dataConso={currentProd.conso}
                currentHour={currentProd.hour}
              />
            </div>
            <div className="col-12 mt-3 mb-2">
              <h5 className='mb-0'>{`Solde des échanges :`}</h5>
              <strong>{`${currentProd.solde} ${currentProd.sense}`}</strong>
            </div>
            <div className="col-12">
              <div className="row mb-2">
                <div className="col-2 text-start">
                  <img src={Export} alt="Export" />
                </div>
                <div className="col-10 fs-6">{`Export ${currentProd.export}`}</div>
              </div>
              <div className="row mb-2">
                <div className="col-2 text-start">
                  <img src={Import} alt="Import" />
                </div>
                <div className="col-10 fs-6">{`Import ${currentProd.import}`}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LeftAreaComponent;
