import React from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  selectCurrentZoomActive,
  setCurrentZoomActive,
  selectStateClimate,
  setSegments,
} from '../../../store/mainReducer';
import { CURRENT_YEAR } from '../../../utils/enums';
import { getSegmentsByYear } from '../../../services';

const StyledDiv = styled.div`
  width: 235px;
  height: 23px;
  position: absolute;
  cursor: pointer;
  bottom: 1rem;
  left: 1rem;
  z-index: 2;
  padding-top: 0.15rem;
  .switch-button {
    width: 40px;
    height: 15px;
    position: absolute;
    left: 0;
    top: 4px;
    background-color: #999fa1;
    border-radius: 60px;
    transition: background-color 0.3s;
    &.on {
      background-color: #82d4f6;
    }
  }
  .switch-circle {
    width: 22.5px;
    height: 23px;
    position: absolute;
    left: 22.5px;
    top: 0;
    background: #ffffff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    border-radius: 23px;
    transition: transform 0.3s, background-color 0.3s;
    &.on {
      transform: translateX(-25px);
      background-color: #009cdf;
    }
  }
`;
const ZoomSwitcher = () => {
  const currentZoomActive = useAppSelector(selectCurrentZoomActive);
  const stateClimat = useAppSelector(selectStateClimate);
  const dispatch = useAppDispatch();
  const handleToggle = () => {
    dispatch(setCurrentZoomActive(!currentZoomActive));
    dispatch(setSegments(getSegmentsByYear(CURRENT_YEAR.YEAR_2030)));
  };
  return (
    <StyledDiv onClick={handleToggle}>
      <div className={`switch-button ${currentZoomActive ? 'on' : 'off'}`} />
      <div className={`switch-circle ${currentZoomActive ? 'on' : 'off'}`} />
      <span>{'Adaptations envisagées'}</span>
    </StyledDiv>
  );
};

export default ZoomSwitcher;
