import { useAppSelector } from './store/hooks';
import { selectCurrentGouletZone } from './store/mainReducer';

import InitMap from './components/initMap';
import GouletComponent from './components/goulet';

function App() {
  const currentGouletZone = useAppSelector(selectCurrentGouletZone);

  return <>{currentGouletZone === '' ? <InitMap /> : <GouletComponent />}</>;
}

export default App;
