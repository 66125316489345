import * as React from 'react';

import styled from 'styled-components';
import CloseModal from '../../assets/images/closeModal.svg';
import {
  selectElementHeightPointIn,
  selectNumberActivePointIn,
  setNumberActivePointIn,
} from '../../store/mainReducer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

import Number1 from '../../assets/images/points/number1.svg';
import Number2 from '../../assets/images/points/number2.svg';
import Number3 from '../../assets/images/points/number3.svg';
import Number4 from '../../assets/images/points/number4.svg';
import ArrowPoint1 from '../../assets/images/points/arrowPoint1.svg';
import ArrowPoint2 from '../../assets/images/points/arrowPoint2.svg';
import ArrowPoint3 from '../../assets/images/points/arrowPoint3.svg';

import MapPointInComponent from './MapPointInComponent';
import ZoomSwitcher from '../initMap/map-area/ZoomSwitcher';

interface ContentProps {
  handleClose: () => void;
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
`;

const ContentPointInComponent: React.FC<ContentProps> = ({ handleClose }) => {
  const dispatch = useAppDispatch();

  const heightArea = useAppSelector(selectElementHeightPointIn);
  const activePointIn = useAppSelector(selectNumberActivePointIn);

  const contentWidth = heightArea ? heightArea - 40 : 696;
  const radialWidth = heightArea
    ? Number(heightArea - 40) / 2
    : Number(696 / 2);

  const handleCloseModal = (): void => {
    handleClose();
  };

  const styleOverlay = {
    background: `radial-gradient(
      circle,
      transparent ${radialWidth}px,
      rgba(0, 0, 0, 0.8) 58%
    )`,
  };

  const styleContainer = {
    width: `${contentWidth}px`,
    height: `${contentWidth}px`,
  };

  const handleMouseEnter = (value: number) => {
    // console.log('value', value);
    dispatch(setNumberActivePointIn(value));
  };

  const handleMouseLeave = () => {
    dispatch(setNumberActivePointIn(0));
  };

  return (
    <MapPointInComponent>
      <StyledContainer>
        <div className="point-overlay" style={styleOverlay}></div>
        <div
          className="point-container"
          style={styleContainer}
          onClick={(e) => e.stopPropagation()}
        >
          <button
            className="point-close"
            onClick={handleCloseModal}
            style={{ position: 'absolute', top: '1.4rem' }}
          >
            <img src={CloseModal} alt="" />
          </button>
          <div className="point-content">
            <img
              src={Number1}
              alt=""
              className="point-number number-1"
              onMouseEnter={() => handleMouseEnter(1)}
              onMouseLeave={handleMouseLeave}
              style={{
                opacity: activePointIn === 1 || activePointIn === 0 ? 1 : 0.1,
              }}
            />
            <img
              src={Number2}
              alt=""
              className="point-number number-2"
              onMouseEnter={() => handleMouseEnter(2)}
              onMouseLeave={handleMouseLeave}
              style={{
                opacity: activePointIn === 2 || activePointIn === 0 ? 1 : 0.1,
              }}
            />
            <img
              src={Number3}
              alt=""
              className="point-number number-3"
              onMouseEnter={() => handleMouseEnter(3)}
              onMouseLeave={handleMouseLeave}
              style={{
                opacity: activePointIn === 3 || activePointIn === 0 ? 1 : 0.1,
              }}
            />
            {/* <img
              src={Number4}
              alt=""
              className="point-number number-4"
              onMouseEnter={() => handleMouseEnter(4)}
              onMouseLeave={handleMouseLeave}
              style={{
                opacity: activePointIn === 4 || activePointIn === 0 ? 1 : 0.1,
              }}
            /> */}
            <img
              src={ArrowPoint1}
              alt=""
              className="arrow-1"
              style={{
                opacity: activePointIn === 2 || activePointIn === 0 ? 1 : 0.1,
              }}
            />
            <img
              src={ArrowPoint2}
              alt=""
              className="arrow-2"
              style={{
                opacity: activePointIn === 2 || activePointIn === 0 ? 1 : 0.1,
              }}
            />
            <img
              src={ArrowPoint3}
              alt=""
              className="arrow-3"
              style={{
                opacity: activePointIn === 2 || activePointIn === 0 ? 1 : 0.1,
              }}
            />
          </div>
        </div>
        <ZoomSwitcher />
      </StyledContainer>
    </MapPointInComponent>
  );
};

export default ContentPointInComponent;
