import { useAppSelector } from '../../store/hooks';
import styled from 'styled-components';
import {
  selectStateClimate,
  selectSidebarProductions,
  selectCurrentYear,
  selectNumberActivePointIn,
} from '../../store/mainReducer';
import { CURRENT_YEAR } from '../../utils/enums';

import DATA_RIGHT_SIDEBAR from '../../data/points/data_right_sidebar.json';

const StyledDiv = styled.div`
  h5 {
    text-transform: capitalize;
  }
  .number {
    border: 1px solid #fff;
    text-align: center;
    font-weight: 600;
    padding: 5px 8px;
    margin-right: 1rem;
    display: inline;
    border-radius: 0.2rem;
  }
`;

const RightPointComponent = () => {
  const currentYear = useAppSelector(selectCurrentYear);
  const activePointIn = useAppSelector(selectNumberActivePointIn);

  const currentData = DATA_RIGHT_SIDEBAR.filter(
    (elt: any) => elt.year === '2030',
  )[0];

  return (
    <div className="right-area">
      {currentData && (
        <div className="row p-2">
          <div className="col-12 text-center pt-3">
            {currentData.title && <h5>{currentData.title}</h5>}
          </div>
          <hr />
          <div className="col-12">
            <div
              className="p-2 text"
              dangerouslySetInnerHTML={{
                __html: currentData.text,
              }}
            />
          </div>
          <div className="col-12 pt-2">
            {currentData.steps.map((item: any, index: number) => {
              return (
                <StyledDiv
                  className="row pt-3"
                  key={index}
                  style={{
                    opacity:
                      activePointIn === item.number || activePointIn === 0
                        ? 1
                        : 0.1,
                  }}
                >
                  <div className="col-2">
                    <div className="number">{`0${item.number}`}</div>
                  </div>
                  <div className="col-10 px-2">
                    {item.title && <h5>{item.title}</h5>}
                  </div>
                  <div className="col-12">
                    <div
                      className="p-2 text"
                      dangerouslySetInnerHTML={{
                        __html: item.text,
                      }}
                    />
                  </div>
                </StyledDiv>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default RightPointComponent;
