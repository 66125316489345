import React, { useRef } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import styled from 'styled-components';
import {
  getDataProd,
  optionsChart,
  verticalFixedLinePlugin,
} from '../utils/chart';

// Register the required components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
);

interface AreaChartProps {
  currentHour: string;
  dataConso: any[];
}

const StyledDiv = styled.div`
  &.area {
    padding-top: 0.6rem;
    width: 100% !important;
    height: 240px !important;
  }
`;

const AreaChart: React.FC<AreaChartProps> = ({ dataConso, currentHour }) => {
  const chartRef = useRef(null);
  // get index current hour by data conso by year
  const selectHour = dataConso.map((elt: any) => elt.hour).indexOf(currentHour);

  return (
    <StyledDiv className="area">
      {dataConso && (
        <Line
          ref={chartRef}
          data={getDataProd(dataConso)}
          options={optionsChart}
          plugins={[verticalFixedLinePlugin(selectHour, currentHour)]}
          width={'100%'}
          height={'240'}
          redraw={true}
        />
      )}
    </StyledDiv>
  );
};

export default AreaChart;
