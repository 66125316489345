import { useAppSelector } from '../../../store/hooks';
import {
  selectNumberActivePointIn,
  selectPostPointIn,
} from '../../../store/mainReducer';
import { PostPointFeature } from '../../../models/ZoomFeature';
import { TextLayer, IconLayer } from '@deck.gl/layers/typed';
import Union from "../../../assets/images/points/Union.svg";

const usePostPointLayer = () => {
  const postPointIn = useAppSelector(selectPostPointIn);
  const activePointIn = useAppSelector(selectNumberActivePointIn);

  const layerLignePoint = new TextLayer<PostPointFeature>({
    id: 'post-point-layer',
    data: postPointIn,
    getText: (d: any) => d.properties.name,
    getPosition: d => d.position as any,
    getAlignmentBaseline: 'center',
    getColor: [0, 0, 0],
    getBackgroundColor: [21, 38, 44, 160],
    backgroundPadding: [4, 4, 4, 4],
    visible: true,
    pickable: true,
    getSize: 16,
    fontWeight: 'bold',
    getTextAnchor: 'middle',
    background: false,
    getPixelOffset: [0, -28],
    characterSet: 'auto',
    outlineWidth: 4,
    outlineColor: [255, 255, 255],
    getBorderColor: [255, 255, 255],
    getBorderWidth: 0,
    fontFamily: 'Nunito Sans',
    billboard: true,
    opacity: activePointIn === 4 || activePointIn === 0 ? 1 : 1,
  });

  const layer = new IconLayer({
    id: 'icon-layer-1',
    data: postPointIn,
    getIcon: (d: any) => ({ url: Union, width: 21, height: 68}),
    sizeMinPixels: 32,
    getPosition: (d: any) => {
      return d.position
    },
    getColor: [255, 255, 0],
    getPixelOffset: [0, -10],
    opacity: activePointIn === 4 || activePointIn === 0 ? 1 : 1,
    pickable: true
  });

  return [layerLignePoint, layer];
};

export default usePostPointLayer;
