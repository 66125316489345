import * as React from 'react';
import { DeckProps } from '@deck.gl/core/typed';
import { MapboxOverlay } from '@deck.gl/mapbox/typed';
import Map, { useControl } from 'react-map-gl';

import { MAPBOX_ACCESS_TOKEN } from '../../utils/constants';
import useLignesPointLayer from '../layers/poinIn/useLignesPointLayer';


import usePostPointLayer from '../layers/poinIn/usePostPointLayer';

import useIndustryPointLayer from '../layers/poinIn/useIndustryPointLayer';

interface ContentProps {
  children?: React.ReactNode;
}

function DeckGLOverlay(props: DeckProps) {
  const deck = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  deck.setProps(props);
  return null;
}

// Initial view state of the map
const initialViewState = {
  longitude: 0.37860816681134946,
  latitude: 49.49619293459191,
  zoom: 12.9,
  pitch: 0,
  bearing: 0,
};

const MapPointInComponent: React.FC<ContentProps> = ({ children }) => {

  const lignesPointLayer = useLignesPointLayer();
  const industryPointLayer = useIndustryPointLayer();
  const postPointLayer = usePostPointLayer();

  return (
    <Map
      reuseMaps
      initialViewState={initialViewState}
      mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
      mapStyle="mapbox://styles/mapbox/streets-v11"
      dragRotate={false}
      dragPan={false}
      touchZoomRotate={false}
      doubleClickZoom={false}
      scrollZoom={false}
    >
      <DeckGLOverlay
        layers={[...lignesPointLayer, ...industryPointLayer, ...postPointLayer]}
      />
      {children}
    </Map>
  );
};

export default MapPointInComponent;
