import { GouletPathFeature } from '../../../models/GouletFeature';
import { useAppSelector } from '../../../store/hooks';
import { PathLayer } from '@deck.gl/layers/typed';
import {
  selectGouletsPathByYear,
} from '../../../store/mainReducer';

const usePathGouletLayer = (): Array<PathLayer<GouletPathFeature>> => {
  const paths = useAppSelector(selectGouletsPathByYear);
  // const goulets_data = useAppSelector(selectGouletsByYear);
  /*
  const gouletGroups = new Set(
    goulets_data.map((item) => item.properties.zone),
  );
  // Create a set of ids from DATA_LIGNES_GOULETS
  const filteredJson = paths.filter((item) =>
    gouletGroups.has(item.properties.zone),
  );
  */
  const paths_1 = new PathLayer<GouletPathFeature>({
    id: 'linegoulet',
    data: paths,
    getPath: (d: any) => d.path,
    getWidth: () => 6,
    widthUnits: 'pixels',
    getColor: () => [231, 0, 0],
    pickable: true,
  });

  return [paths_1];
};

export default usePathGouletLayer;
