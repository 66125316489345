import React from 'react';
import { Row } from 'react-bootstrap';

import styled from 'styled-components';
import Eye from '../../../../assets/images/eye.svg';
import EyeClose from '../../../../assets/images/eye-close.svg';
import TooltipTrigger from '../../../../shared/TooltipTrigger';

import Tooltip from '../../../../assets/images/tooltip.svg';

const StyledRow = styled(Row)`
  .icon {
    width: 1.4rem;
  }
  .info {
    width: 0.95rem;
    position: relative;
    bottom: 1px;
  }
`;

interface ItemsTitleProps {
  active?: boolean;
  handleClick?: () => void;
  title: string;
  src: string;
  displayEye?: boolean;
  tooltip?: boolean;
}
const ItemLegend: React.FC<ItemsTitleProps> = ({
  handleClick,
  active,
  title,
  src,
  displayEye,
  tooltip,
}) => {
  return (
    <StyledRow className="align-items-center mb-2 fs-6">
      <div className="col-2">
        <img className="icon" src={src} alt={title} />
      </div>
      <div className="col-8">
        <div className="row align-items-center">
          {tooltip ? (
            <div className="col-auto d-flex align-items-center">
              <span>{title}</span>&nbsp;
              <TooltipTrigger description="Courant Continu Haute Tension">
                <img className="info" src={Tooltip} alt={title} />
              </TooltipTrigger>
            </div>
          ) : (
            <div className="col-auto">{title}</div>
          )}
        </div>
      </div>
      {displayEye && (
        <div
          onClick={handleClick}
          style={{ cursor: 'pointer' }}
          className="col-2 text-end"
        >
          <img src={active ? Eye : EyeClose} alt="Eye" />
        </div>
      )}
    </StyledRow>
  );
};

export default ItemLegend;
