import React from 'react';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';

import { useAppSelector } from '../../store/hooks';
import { selectNumberActivePointIn } from '../../store/mainReducer';

import Ligne400kv from '../../assets/images/points/ligne400kv.svg';
import Ligne225kv from '../../assets/images/points/ligne225kv.svg';
import Post from '../../assets/images/points/post.svg';
import Flux1 from '../../assets/images/points/flux1.svg';
import Flux2 from '../../assets/images/points/flux2.svg';
import Industry from '../../assets/images/points/industry.svg';

const StyledDiv = styled.div`
  color: rgba(255, 255, 255, 1);
  background-color: rgba(10, 90, 114, 1);
  border: none;
  margin-bottom: 1rem;
  border-radius: 0.6rem;
  padding-left: 0.5rem;
  min-height: 25rem;
  .icon {
    width: 1.4rem;
  }
  .info {
    text-wrap: nowrap;
    font-size: 0.9rem;
  }
`;

const ItemLegendPoint = () => {
  const activePointIn = useAppSelector(selectNumberActivePointIn);

  return (
    <StyledDiv>
      <div className="col-12  mb-1 mt-3">
        <h5>{'Légende :'}</h5>
      </div>
      <Row
        className="align-items-center mb-2"
        style={{
          opacity: activePointIn === 1 || activePointIn === 0 ? 1 : 0.1,
        }}
      >
        <div className="col-2">
          <img className="icon" src={Ligne400kv} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">
            {'Ligne 400kV surchargée'}
          </div>
        </div>
      </Row>
      <Row
        className="align-items-center mb-2"
        style={{
          opacity: activePointIn === 1 || activePointIn === 0 ? 1 : 0.1,
        }}
      >
        <div className="col-2">
          <img className="icon" src={Ligne225kv} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">
            {'Ligne 225kV surchargée'}
          </div>
        </div>
      </Row>
      <Row
        className="align-items-center mb-2"
        style={{
          opacity: activePointIn === 4 || activePointIn === 0 ? 1 : 0.1,
        }}
      >
        <div className="col-2">
          <img className="icon" src={Post} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">{'Postes'}</div>
        </div>
      </Row>
      <Row
        className="align-items-center mb-2"
        style={{
          opacity: activePointIn === 2 || activePointIn === 0 ? 1 : 0.1,
        }}
      >
        <div className="col-2">
          <img className="icon" src={Flux1} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">{'Flux nucléaire'}</div>
        </div>
      </Row>
      <Row
        className="align-items-center mb-2"
        style={{
          opacity: activePointIn === 2 || activePointIn === 0 ? 1 : 0.1,
        }}
      >
        <div className="col-2">
          <img className="icon" src={Flux2} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">{'Flux nucléaire'}</div>
        </div>
      </Row>
      <Row
        className="align-items-center mb-2"
        style={{
          opacity: activePointIn === 3 || activePointIn === 0 ? 1 : 0.1,
        }}
      >
        <div className="col-2">
          <img className="icon" src={Industry} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">
            {'Consommations/Industries'}
          </div>
        </div>
      </Row>
    </StyledDiv>
  );
};

export default ItemLegendPoint;
