import { useState } from 'react';

import { GouletIconFeature } from '../../../models/GouletFeature';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { IconLayer, TextLayer } from '@deck.gl/layers/typed';
import { PickingInfo } from '@deck.gl/core/typed';
import IconsMap from '../../../assets/images/iconsPoint.png';
import LineIcons from '../../../assets/images/points/lineIcons.svg';

import {
  selectCurrentYear,
  selectZoomPointsByYear,
  setCurrentZoom,
  setCurrentZoomActive,
} from '../../../store/mainReducer';
import { CURRENT_YEAR } from '../../../utils/enums';

const usePointIconsLayer = () => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  // Define icon mappings for normal and hover states
  const iconMapping = {
    normal: { x: 4, y: 4.8, width: 40, height: 40, mask: false },
    hover: { x: 4.3, y: 61.3, width: 40, height: 40, mask: false },
  };

  const dispatch = useAppDispatch();
  const icons = useAppSelector(selectZoomPointsByYear);
  const currentYear = useAppSelector(selectCurrentYear);

  const zoomToPoint = (e: PickingInfo) => {
    dispatch(setCurrentZoom(e.object));
    dispatch(setCurrentZoomActive(true));
  };

  const layer = new IconLayer<GouletIconFeature>({
    id: 'points-icon-layer',
    data: icons.filter(
      () =>
        CURRENT_YEAR.YEAR_2030 === currentYear ||
        CURRENT_YEAR.YEAR_2024 === currentYear,
    ),
    pickable: true,
    iconAtlas: IconsMap, // Your image containing all icons
    iconMapping,
    sizeScale: 6,
    getSize: 5,
    getPosition: (d: any) => {
      return d.position;
    },
    getColor: [255, 255, 0],
    getIcon: (d, { index }) => (index === hoveredIndex ? 'hover' : 'normal'),
    onHover: ({ layer, x, y, object, index }) => {
      if (index !== -1) {
        setHoveredIndex(index);
      } else {
        setHoveredIndex(null);
      }
    },
    onClick: zoomToPoint,
    updateTriggers: {
      getIcon: [hoveredIndex], // Re-render when data changes
    },
    visible: true,
  });

  const layerNamePoint = new TextLayer({
    id: 'names-point-layer',
    data: icons.filter(
      () =>
        CURRENT_YEAR.YEAR_2030 === currentYear ||
        CURRENT_YEAR.YEAR_2024 === currentYear,
    ),
    getText: (d: any) => d.properties.Nom_comp,
    getPosition: (d: any) => d.position as any,
    getAlignmentBaseline: 'center',
    getColor: (d, { index }) =>
      index === hoveredIndex ? [255, 255, 255, 255] : [255, 255, 255, 0],
    backgroundPadding: [2, 2, 2, 2],
    pickable: true,
    getSize: 13,
    fontWeight: 'bold',
    getTextAnchor: 'start',
    background: false,
    getPixelOffset: (d: any) =>
      d.properties.Nom === 'SSHAVRE' ? [-90, -25] : [-110, -25],
    characterSet: 'auto',
    outlineWidth: 1,
    maxWidth: 6.0,
    wordBreak: 'break-word',
    getBorderWidth: 0,
    fontFamily: 'Nunito Sans',
    billboard: true,
  });

  const layerLignePoint = new IconLayer({
    id: 'ligne-point-layer',
    data: icons.filter(
      () =>
        CURRENT_YEAR.YEAR_2030 === currentYear ||
        CURRENT_YEAR.YEAR_2024 === currentYear,
    ),
    getIcon: (d: any) => ({
      url: LineIcons,
      width: 29,
      height: 16,
    }),
    getPosition: (d: any) => {
      return d.position;
    },
    getPixelOffset: [-20, -20],
    getSize: (d) => 16,
    visible: true,
    pickable: true,
    getColor: (d, { index }) =>
      index === hoveredIndex ? [255, 255, 255, 255] : [255, 255, 255, 0],
  });

  return [layer, layerNamePoint, layerLignePoint];
};

export default usePointIconsLayer;
