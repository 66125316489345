import { LineString, Polygon, Point } from 'geojson';
import { SegmentFeature, SegmentProperties } from '../models/SegmentFeature';
import { LOOP_TRIPS_LENGTH } from '../utils/constants';
import {
  getCurrentColor,
  getLignePointColor,
  getLignePointSize,
} from '../utils';
import { SiteFeature, SiteProperties } from '../models/SiteFeature';
import {
  GouletFeature,
  GouletIconFeature,
  GouletPathFeature,
  GouletPathProperties,
  GouletProperties,
  GridFeature,
  GridProperties,
} from '../models/GouletFeature';
import {
  IndustryPointFeature,
  IndustryPointProperties,
  LignePointFeature,
  LignePointProperties,
  NumberPointFeature,
  NumberPointProperties,
  PostPointFeature,
  PostPointProperties,
  ZoomPointFeature,
  ZoomPointProperties,
} from '../models/ZoomFeature';
// Start Sites
import SITES_2024_EJCS from '../data/sites/2024_IMAGRID_AREF_EJCS.json';
import SITES_2024_HNVC from '../data/sites/2024_IMAGRID_AREF_HNVC.json';
// End Sites
// Start B-ref
import SITES_2030_EJCS_REFB from '../data/sites/SITES_HISTB_2030_EJCS_V1.json';
import SITES_2030_HNVC_REFB from '../data/sites/SITES_HISTB_2030_HNVC_V1.json';
import SITES_2035_EJCS_REFB from '../data/sites/SITES_HISTB_2035_EJCS_V1.json';
import SITES_2035_HNVC_REFB from '../data/sites/SITES_HISTB_2035_HNVC_V1.json';
import SITES_2040_EJCS_REFB from '../data/sites/SITES_HISTB_2040_EJCS_V1.json';
import SITES_2040_HNVC_REFB from '../data/sites/SITES_HISTB_2040_HNVC_V1.json';
// End B-ref

// LIGNES MIX
import LINES_2024 from '../data/lignes/LINES_2024.json';
import LINES_2030 from '../data/lignes/LINES_2030.json';
import LINES_2035 from '../data/lignes/LINES_2035.json';
import LINES_2040 from '../data/lignes/LINES_2040.json';

// LIGNES WITH DAY CLIMAT
import CLIMAT_LINES_2024 from '../data/lignes/CLIMAT_LINES_2024.json';
import CLIMAT_LINES_2030 from '../data/lignes/CLIMAT_LINES_2030.json';
import CLIMAT_LINES_2035 from '../data/lignes/CLIMAT_LINES_2030.json';
import CLIMAT_LINES_2040 from '../data/lignes/CLIMAT_LINES_2030.json';

import dataProd_REF_B_2024 from '../data/prodSidebar/dataProd_REF_B_2024.json';
import dataProd_REF_B_2030 from '../data/prodSidebar/dataProd_REF_B_2030.json';
import dataProd_REF_B_2035 from '../data/prodSidebar/dataProd_REF_B_2035.json';
import dataProd_REF_B_2040 from '../data/prodSidebar/dataProd_REF_B_2040.json';

import DATA_GOULET from '../data/goulet/GOULETS_V2_20240708.json';
import DATA_ZOOM_POINTS from '../data/goulet/ZIPE_PTS.json';
import DATA_SOLAIRE_GOULETS from '../data/goulet/GRILLE_2030_EJCS_B_V0.json';
// import DATA_SOLAIRE_GOULETS from '../data/goulet/PTS_SOLAIRE_GOULETS.json';
import DATA_ICONS_GOULETS from '../data/goulet/GOULETS_PTS.json';
import DATA_LIGNES_GOULETS from '../data/goulet/LIGNES_GRAS_V1.json';

import DATA_POINT_LINGES_HAVRE from '../data/points/LIGNES_HAVRE_V0.json';
import DATA_POINT_INDUSTRY_HAVRE from '../data/points/PTS_INDUSTRIE_HAVRE_V0.json';
import DATA_POINT_NUMBER_HAVRE from '../data/points/PTS_NUMERO_HAVRE_V0.json';
import DATA_POINT_POST_HAVRE from '../data/points/PTS_POSTE_HAVRE_V0.json';
import ICONES_GOULETS_V0 from '../data/goulet/ICONES_GOULETS_V0.json';

import Number1 from '../assets/images/points/number1.svg';
import Number2 from '../assets/images/points/number2.svg';
import Number3 from '../assets/images/points/number3.svg';
import Number4 from '../assets/images/points/number4.svg';
import { GouletSymbolFeature, GouletSymbolProperties } from '../models/GouletSymbolFeature';

const getLignesByYear = (year: string): any => {
  if (year === 'YEAR_2024') {
    console.log('test 3 no day');
    return LINES_2024;
  } else if (year === 'YEAR_2030') {
    return LINES_2030;
  } else if (year === 'YEAR_2035') {
    return LINES_2035;
  } else if (year === 'YEAR_2040') {
    return LINES_2040;
  }
};

const getLignesByDay = (year: string, day: string): any => {
  if (year === 'YEAR_2024') {
    return CLIMAT_LINES_2024.filter((elt) => elt.name === day)[0];
  } else if (year === 'YEAR_2030') {
    return CLIMAT_LINES_2030.filter((elt) => elt.name === day)[0];
  } else if (year === 'YEAR_2035') {
    return CLIMAT_LINES_2035.filter((elt) => elt.name === day)[0];
  } else if (year === 'YEAR_2040') {
    return CLIMAT_LINES_2040.filter((elt) => elt.name === day)[0];
  }
};

export const getDataSiteByYear = (year: string): any => {
  if (year === 'YEAR_2024') {
    return SITES_2024_EJCS;
  }
  if (year === 'YEAR_2030') {
    return SITES_2030_EJCS_REFB;
  }
  if (year === 'YEAR_2035') {
    return SITES_2035_EJCS_REFB;
  }
  if (year === 'YEAR_2040') {
    return SITES_2040_EJCS_REFB;
  }
};

export const getDataSiteByDay = (day: string, year: string): any => {
  if (year === 'YEAR_2024') {
    switch (day) {
      case 'EJCS':
        return SITES_2024_EJCS;
      case 'HNVC':
        return SITES_2024_HNVC;
    }
  }
  if (year === 'YEAR_2030') {
    switch (day) {
      case 'EJCS':
        return SITES_2030_EJCS_REFB;
      case 'HNVC':
        return SITES_2030_HNVC_REFB;
    }
  }
  if (year === 'YEAR_2035') {
    switch (day) {
      case 'EJCS':
        return SITES_2035_EJCS_REFB;
      case 'HNVC':
        return SITES_2035_HNVC_REFB;
    }
  }
  if (year === 'YEAR_2040') {
    switch (day) {
      case 'EJCS':
        return SITES_2040_EJCS_REFB;
      case 'HNVC':
        return SITES_2040_HNVC_REFB;
    }
  }
};

export const getSegmentsByYear = (year: string): SegmentFeature => {
  const segments = getLignesByYear(year);

  const data_segment = segments.features
    .filter((elt: any) => elt.geometry !== null)
    .map(
      (elt: GeoJSON.Feature<LineString, SegmentProperties>): SegmentFeature => {
        return {
          year: segments.year,
          name: segments.name,
          title: segments.title,
          text: segments.text,
          dashed: false,
          properties: { ...elt.properties },
          path: elt.geometry.coordinates,
          timestamps: [0, LOOP_TRIPS_LENGTH],
          width: 1,
          color: getCurrentColor(Number(elt.properties.etat)),
        };
      },
    );
  return data_segment;
};

export const getSegmentsByDay = (year: string, day: string): SegmentFeature => {
  const segments = getLignesByDay(year, day);

  const data_segment = segments.features
    .filter((elt: any) => elt.geometry !== null)
    .map(
      (elt: GeoJSON.Feature<LineString, SegmentProperties>): SegmentFeature => {
        return {
          year: segments.year,
          name: segments.name,
          title: segments.title,
          text: segments.text,
          dashed: false,
          properties: { ...elt.properties },
          path: elt.geometry.coordinates,
          timestamps: [0, LOOP_TRIPS_LENGTH],
          width: 1,
          color: getCurrentColor(Number(elt.properties.etat)),
        };
      },
    );
  return data_segment;
};

export const getSeuil = (conso: number) => {
  if (conso >= 450) {
    return 10;
  }
  if (conso < 450 && conso >= 340) {
    return 20;
  }
  return 30;
};

export const getSidebarProd = (year: string) => {
    switch (year) {
      case 'YEAR_2024':
        return dataProd_REF_B_2024;
      case 'YEAR_2030':
        return dataProd_REF_B_2030;
      case 'YEAR_2035':
        return dataProd_REF_B_2035;
      case 'YEAR_2040':
        return dataProd_REF_B_2040;
    }
};

export const getSitesByYear = (year: string): SiteFeature => {
  const data_sites = getDataSiteByYear(year);
  const sites = (data_sites as any).features
    .filter((elt: any) => elt.geometry !== null)
    .map((elt: GeoJSON.Feature<Polygon, SiteProperties>): SiteFeature => {
      return {
        properties: { ...elt.properties },
        position: elt.geometry.coordinates,
        size: 1000,
        visible: true,
        seuil: getSeuil(elt.properties.TOTAL_CONSO),
        conso: elt.properties.TOTAL_CONSO,
        prod: elt?.properties?.TOTAL_PROD ? elt?.properties?.TOTAL_PROD : 0,
        tooltip: {
          position: elt.geometry.coordinates,
          content: 'Détails:',
        },
      };
    });
  return sites;
};

export const getSitesByDay = (year: string, current_day: string): SiteFeature => {
  const data_sites = getDataSiteByDay(current_day, year);
  const sites = (data_sites as any).features
    .filter((elt: any) => elt.geometry !== null)
    .map((elt: GeoJSON.Feature<Polygon, SiteProperties>): SiteFeature => {
      return {
        properties: { ...elt.properties },
        position: elt.geometry.coordinates,
        size: 1000,
        visible: true,
        seuil: getSeuil(elt.properties.TOTAL_CONSO),
        conso: elt.properties.TOTAL_CONSO,
        prod: elt?.properties?.TOTAL_PROD ? elt?.properties?.TOTAL_PROD : 0,
        tooltip: {
          position: elt.geometry.coordinates,
          content: 'Détails:',
        },
      };
    });
  return sites;
};

export const getGoulet = (): GouletFeature => {
  const goulets = (DATA_GOULET as any).features.map(
    (elt: GeoJSON.Feature<Polygon, GouletProperties>): GouletFeature => {
      return {
        properties: { ...elt.properties },
        position: elt.geometry.coordinates,
      };
    },
  );
  return goulets;
};
export const getGouletIcons = (): GouletIconFeature => {
  const goulets = (DATA_ICONS_GOULETS as any).features.map(
    (elt: GeoJSON.Feature<Point, GouletProperties>): GouletIconFeature => {
      return {
        properties: { ...elt.properties },
        position: elt.geometry.coordinates,
      };
    },
  );
  return goulets;
};

export const getGouletPaths = (): GouletPathFeature => {
  const paths = (DATA_LIGNES_GOULETS as any).features.map(
    (
      elt: GeoJSON.Feature<LineString, GouletPathProperties>,
    ): GouletPathFeature => {
      return {
        year: DATA_LIGNES_GOULETS.year,
        properties: { ...elt.properties },
        path: elt.geometry.coordinates,
        timestamps: [0, LOOP_TRIPS_LENGTH],
      };
    },
  );
  return paths;
};

export const getGouletGrid = (): GridFeature => {
  const grid = (DATA_SOLAIRE_GOULETS as any).features.map(
    (elt: GeoJSON.Feature<Point, GridProperties>): GridFeature => {
      return {
        properties: { ...elt.properties },
        position: elt.geometry.coordinates,
      };
    },
  );

  return grid;
};

export const getZoomPoints = (): ZoomPointFeature => {
  const goulets = (DATA_ZOOM_POINTS as any).features.map(
    (elt: GeoJSON.Feature<Point, ZoomPointProperties>): ZoomPointFeature => {
      return {
        properties: { ...elt.properties },
        position: elt.geometry.coordinates,
      };
    },
  );
  return goulets;
};

export const getLignesPoints = (): LignePointFeature => {
  const paths = (DATA_POINT_LINGES_HAVRE as any).features.map(
    (
      elt: GeoJSON.Feature<LineString, LignePointProperties>,
    ): LignePointFeature => {
      return {
        properties: { ...elt.properties },
        position: elt.geometry.coordinates,
        size: getLignePointSize(elt.properties.tension),
        color: getLignePointColor(elt.properties.tension, 255),
      };
    },
  );
  return paths;
};

export const getIndustryPoints = (): IndustryPointFeature => {
  const points = (DATA_POINT_INDUSTRY_HAVRE as any).features.map(
    (
      elt: GeoJSON.Feature<Point, IndustryPointProperties>,
    ): IndustryPointFeature => {
      return {
        properties: { ...elt.properties },
        position: elt.geometry.coordinates,
      };
    },
  );
  return points;
};

const getNumbeIcons = (id: number) => {
  switch (id) {
    case 1:
      return Number1;
    case 2:
      return Number2;
    case 3:
      return Number3;
    case 4:
      return Number4;
  }
};

export const getNumberPoints = (): NumberPointFeature => {
  const points = (DATA_POINT_NUMBER_HAVRE as any).features.map(
    (
      elt: GeoJSON.Feature<Point, NumberPointProperties>,
    ): NumberPointFeature => {
      return {
        id: elt.properties.id,
        properties: { ...elt.properties },
        position: elt.geometry.coordinates,
        icon: getNumbeIcons(elt.properties.id),
        color: [255, 0, 0, 255],
      };
    },
  );
  return points;
};

export const getPostsPoints = (): PostPointFeature => {
  const points = (DATA_POINT_POST_HAVRE as any).features.map(
    (elt: GeoJSON.Feature<Point, PostPointProperties>): PostPointFeature => {
      return {
        properties: { ...elt.properties },
        position: elt.geometry.coordinates,
      };
    },
  );
  return points;
};


export const getGouletSymbols = (): GouletSymbolFeature => {
  const points = (ICONES_GOULETS_V0 as any).features.map(
    (elt: GeoJSON.Feature<Point, GouletSymbolProperties>): GouletSymbolFeature => {
      return {
        properties: { ...elt.properties },
        position: elt.geometry.coordinates,
      };
    },
  );
  return points;
};