import { IconLayer } from '@deck.gl/layers/typed';

import {
  selectCurrentGouletZone,
  selectCurrentYear,
  selectGouletSymbols,
  selectStateClimate,
} from '../../../store/mainReducer';
import { useAppSelector } from '../../../store/hooks';
import { GouletSymbolFeature } from '../../../models/GouletSymbolFeature';
import { splitYear } from '../../../utils';

import B_2030_EJCS_diffus from '../../../assets/images/symbols/B_2030_EJCS_diffus.svg';
import B_2035_EJCS_diffus from '../../../assets/images/symbols/B_2035_EJCS_diffus.svg';
import B_2040_EJCS_diffus from '../../../assets/images/symbols/B_2040_EJCS_diffus.svg';
import B_2040_HNVC_diffus_eolien_1 from '../../../assets/images/symbols/B_2040_HNVC_diffus_eolien_1.svg';
import B_2040_HNVC_diffus_eolien_2 from '../../../assets/images/symbols/B_2040_HNVC_diffus_eolien_2.svg';
import B_2040_HNVC_diffus_eolien_3 from '../../../assets/images/symbols/B_2040_HNVC_diffus_eolien_3.svg';
import B_2035_HNVC_diffus_eolien_1 from '../../../assets/images/symbols/B_2040_HNVC_diffus_eolien_1.svg';
import B_2035_HNVC_diffus_eolien_3 from '../../../assets/images/symbols/B_2035_HNVC_diffus_eolien_3.svg';
import C_2030_EJCS_diffus from '../../../assets/images/symbols/C_2030_EJCS_diffus.svg';
import C_2035_EJCS_diffus from '../../../assets/images/symbols/C_2035_EJCS_diffus.svg';
import C_2040_EJCS_diffus from '../../../assets/images/symbols/C_2040_EJCS_diffus.svg';

// import C_2040_HNVC_diffus_eolien_1 from '../../../assets/images/symbols/C_2040_HNVC_diffus_eolien_1.svg';
// import C_2040_HNVC_diffus_eolien_2 from '../../../assets/images/symbols/C_2040_HNVC_diffus_eolien_2.svg';
// import C_2040_HNVC_diffus_eolien_3 from '../../../assets/images/symbols/C_2040_HNVC_diffus_eolien_3.svg';
// import C_2035_HNVC_diffus_eolien_1 from '../../../assets/images/symbols/C_2035_HNVC_diffus_eolien_1.svg';
// import C_2035_HNVC_diffus_eolien_3 from '../../../assets/images/symbols/C_2035_HNVC_diffus_eolien_3.svg';

import E_2030_EJCS_diffus from '../../../assets/images/symbols/E_2030_EJCS_diffus.svg';
import E_2035_EJCS_diffus from '../../../assets/images/symbols/E_2035_EJCS_diffus.svg';
import E_2040_EJCS_diffus from '../../../assets/images/symbols/E_2040_EJCS_diffus.svg';

import E_2040_HNVC_diffus_eolien_1 from '../../../assets/images/symbols/E_2040_HNVC_diffus_eolien_1.svg';
import E_2040_HNVC_diffus_eolien_2 from '../../../assets/images/symbols/E_2040_HNVC_diffus_eolien_2.svg';
import E_2040_HNVC_diffus_eolien_3 from '../../../assets/images/symbols/E_2040_HNVC_diffus_eolien_3.svg';
import E_2035_HNVC_diffus_eolien_1 from '../../../assets/images/symbols/E_2035_HNVC_diffus_eolien_1.svg';
import E_2035_HNVC_diffus_eolien_3 from '../../../assets/images/symbols/E_2035_HNVC_diffus_eolien_3.svg';

import I_2040_HNVC_diffus_eolien_1 from '../../../assets/images/symbols/I_2040_HNVC_diffus_eolien_1.svg';
import I_2040_HNVC_diffus_eolien_2 from '../../../assets/images/symbols/I_2040_HNVC_diffus_eolien_2.svg';
import I_2040_HNVC_diffus_eolien_3 from '../../../assets/images/symbols/I_2040_HNVC_diffus_eolien_3.svg';
import I_2035_HNVC_diffus_eolien_1 from '../../../assets/images/symbols/I_2035_HNVC_diffus_eolien_1.svg';
import I_2035_HNVC_diffus_eolien_3 from '../../../assets/images/symbols/I_2035_HNVC_diffus_eolien_3.svg';
import I_2040_HNVC_diffus_eolien_4 from '../../../assets/images/symbols/I_2040_HNVC_diffus_eolien_4.svg';

const useDiffusGouletLayer = () => {
  const currentYear = useAppSelector(selectCurrentYear);
  const currentGouletZone = useAppSelector(selectCurrentGouletZone);
  const currentClimat = useAppSelector(selectStateClimate);
  const gouletSymbol = useAppSelector(selectGouletSymbols);

  const getSymbolData = () => {
    return gouletSymbol.filter((item: GouletSymbolFeature) => {
      const splitCat = item.properties.categorie.split('_');
      return (
        item.properties.zone === currentGouletZone &&
        item.properties.mix === splitYear(currentYear).toString() &&
        item.properties.journee === currentClimat &&
        splitCat[3] === 'diffus'
      );
    });
  };

  const getSymbolIcon = (categorie: string): any => {
    switch (categorie) {
      case 'B_2030_EJCS_diffus':
        return {
          url: B_2030_EJCS_diffus,
          width: 424,
          height: 412,
        };
      case 'B_2035_EJCS_diffus':
        return {
          url: B_2035_EJCS_diffus,
          width: 424,
          height: 412,
        };
      case 'B_2040_EJCS_diffus':
        return {
          url: B_2040_EJCS_diffus,
          width: 424,
          height: 412,
        };
      case 'B_2040_HNVC_diffus_eolien_1':
        return {
          url: B_2040_HNVC_diffus_eolien_1,
          width: 207,
          height: 210,
        };
      case 'B_2040_HNVC_diffus_eolien_2':
        return {
          url: B_2040_HNVC_diffus_eolien_2,
          width: 210,
          height: 120,
        };
      case 'B_2040_HNVC_diffus_eolien_3':
        return {
          url: B_2040_HNVC_diffus_eolien_3,
          width: 260,
          height: 198,
        };
      case 'B_2035_HNVC_diffus_eolien_1':
        return {
          url: B_2035_HNVC_diffus_eolien_1,
          width: 207,
          height: 210,
        };
      case 'B_2035_HNVC_diffus_eolien_3':
        return {
          url: B_2035_HNVC_diffus_eolien_3,
          width: 260,
          height: 198,
        };
      case 'C_2030_EJCS_diffus':
        return {
          url: C_2030_EJCS_diffus,
          width: 330,
          height: 303,
        };
      case 'C_2035_EJCS_diffus':
        return {
          url: C_2035_EJCS_diffus,
          width: 329,
          height: 303,
        };
      case 'C_2040_EJCS_diffus':
        return {
          url: C_2040_EJCS_diffus,
          width: 329,
          height: 303,
        };
      case 'E_2030_EJCS_diffus':
        return {
          url: E_2030_EJCS_diffus,
          width: 229,
          height: 343,
        };
      case 'E_2035_EJCS_diffus':
        return {
          url: E_2035_EJCS_diffus,
          width: 228,
          height: 342,
        };
      case 'E_2040_EJCS_diffus':
        return {
          url: E_2040_EJCS_diffus,
          width: 228,
          height: 342,
        };
      // case 'C_2040_HNVC_diffus_eolien_1':
      //   return {
      //     url: C_2040_HNVC_diffus_eolien_1,
      //     width: 207,
      //     height: 210,
      //   };
      // case 'C_2040_HNVC_diffus_eolien_2':
      //   return {
      //     url: C_2040_HNVC_diffus_eolien_2,
      //     width: 210,
      //     height: 120,
      //   };
      // case 'C_2040_HNVC_diffus_eolien_3':
      //   return {
      //     url: C_2040_HNVC_diffus_eolien_3,
      //     width: 260,
      //     height: 198,
      //   };
      // case 'C_2035_HNVC_diffus_eolien_1':
      //   return {
      //     url: C_2035_HNVC_diffus_eolien_1,
      //     width: 207,
      //     height: 210,
      //   };
      // case 'C_2035_HNVC_diffus_eolien_3':
      //   return {
      //     url: C_2035_HNVC_diffus_eolien_3,
      //     width: 260,
      //     height: 198,
      //   };

      case 'E_2040_HNVC_diffus_eolien_1':
        return {
          url: E_2040_HNVC_diffus_eolien_1,
          width: 207,
          height: 210,
        };
      case 'E_2040_HNVC_diffus_eolien_2':
        return {
          url: E_2040_HNVC_diffus_eolien_2,
          width: 210,
          height: 120,
        };
      case 'E_2040_HNVC_diffus_eolien_3':
        return {
          url: E_2040_HNVC_diffus_eolien_3,
          width: 260,
          height: 198,
        };
      case 'E_2035_HNVC_diffus_eolien_1':
        return {
          url: E_2035_HNVC_diffus_eolien_1,
          width: 207,
          height: 210,
        };
      case 'E_2035_HNVC_diffus_eolien_3':
        return {
          url: E_2035_HNVC_diffus_eolien_3,
          width: 260,
          height: 198,
        };

      case 'I_2040_HNVC_diffus_eolien_1':
        return {
          url: I_2040_HNVC_diffus_eolien_1,
          width: 207,
          height: 210,
        };
      case 'I_2040_HNVC_diffus_eolien_2':
        return {
          url: I_2040_HNVC_diffus_eolien_2,
          width: 210,
          height: 120,
        };
      case 'I_2040_HNVC_diffus_eolien_3':
        return {
          url: I_2040_HNVC_diffus_eolien_3,
          width: 260,
          height: 198,
        };
      case 'I_2035_HNVC_diffus_eolien_1':
        return {
          url: I_2035_HNVC_diffus_eolien_1,
          width: 207,
          height: 210,
        };
      case 'I_2035_HNVC_diffus_eolien_3':
        return {
          url: I_2035_HNVC_diffus_eolien_3,
          width: 260,
          height: 198,
        };
      case 'I_2040_HNVC_diffus_eolien_4':
        return {
          url: I_2040_HNVC_diffus_eolien_4,
          width: 423,
          height: 444,
        };
      default:
        return;
    }
  };

  const getOffsetIcon = (categorie: string): any => {
    switch (categorie) {
      case 'B_2030_EJCS_diffus':
        return [20, 20];
      case 'B_2035_EJCS_diffus':
        return [20, 20];
      case 'B_2040_EJCS_diffus':
        return [20, 20];
      case 'B_2040_HNVC_diffus_eolien_1':
        return [-10, -10];
      case 'B_2040_HNVC_diffus_eolien_2':
        return [0, -10];
      case 'B_2040_HNVC_diffus_eolien_3':
        return [0, -10];
      case 'B_2035_HNVC_diffus_eolien_1':
        return [-35, 0];
      case 'B_2035_HNVC_diffus_eolien_3':
        return [0, -10];
      case 'C_2040_EJCS_diffus':
        return [0, -15];
      case 'E_2030_EJCS_diffus':
        return [-10, -15];
      case 'E_2035_EJCS_diffus':
        return [-10, -15];
      case 'E_2040_EJCS_diffus':
        return [-10, -15];
      case 'C_2040_HNVC_diffus_eolien_1':
        return [-35, 0];
      case 'C_2040_HNVC_diffus_eolien_2':
        return [-15, -15];
      case 'C_2040_HNVC_diffus_eolien_3':
        return [-15, -15];
      case 'C_2035_HNVC_diffus_eolien_1':
        return [0, 5];
      case 'C_2035_HNVC_diffus_eolien_3':
        return [0, 5];
      case 'E_2040_HNVC_diffus_eolien_1':
        return [0, 5];
      case 'E_2040_HNVC_diffus_eolien_2':
        return [0, 5];
      case 'E_2040_HNVC_diffus_eolien_3':
        return [0, 5];
      case 'E_2035_HNVC_diffus_eolien_1':
        return [0, 5];
      case 'E_2035_HNVC_diffus_eolien_3':
        return [0, 5];
      case 'I_2040_HNVC_diffus_eolien_1':
        return [-10, 0];
      case 'I_2040_HNVC_diffus_eolien_2':
        return [0, 10];
      case 'I_2040_HNVC_diffus_eolien_3':
        return [0, 10];
      case 'I_2035_HNVC_diffus_eolien_1':
        return [-35, 0];
      case 'I_2035_HNVC_diffus_eolien_3':
        return [-15, -15];
      default:
        return;
    }
  };

  const layer = new IconLayer({
    id: 'diffus-layer',
    data: getSymbolData(),
    getIcon: (d: any) => getSymbolIcon(d.properties.categorie),
    getPosition: (d: any) => {
      return d.position;
    },
    getSize: (d: any) => getSymbolIcon(d.properties.categorie).height,
    getColor: () => [255, 255, 255, 200],
    getPixelOffset: (d: any) => getOffsetIcon(d.properties.categorie),
    pickable: false,
  });

  return [layer];
};

export default useDiffusGouletLayer;
